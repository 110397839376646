<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <!-- media screen > 992px -->
                                <div class="computer">
                                    <div class="mt-4">
                                        <h5>Nouveaux produits</h5>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de bonne qualité ...
                                            </small>
                                            </div>
                                            <div  class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de très bonne qualité ...
                                            </small>
                                            </div>
                                            <div  class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de bonne qualité ...
                                            </small>
                                            </div>
                                            <div class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h5>Meilleures ventes</h5>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de bonne qualité ...
                                            </small>
                                            </div>
                                            <div  class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de très bonne qualité ...
                                            </small>
                                            </div>
                                            <div  class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                        <div  class="wrapper d-flex align-items-center py-2 border-bottom">
                                            <img  src="../assets/images/import/actu-2.jpg" alt="profile" class="img-sm">
                                            <div  class="wrapper ml-3">
                                            <h6  class="ml-1 mb-1">Miel Bio</h6>
                                            <small  class="text-muted mb-0">
                                                Miel de bonne qualité ...
                                            </small>
                                            </div>
                                            <div class="badge badge-pill badge-success ml-auto px-1 py-1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- media screen < 922px -->
                                <div class="mobile">
                                    <div role="group" class="input-group">
                                        <input type="text" placeholder="Recherche" class="form-control" id="__BVID__226">
                                        <div class="input-group-append">
                                            <div class="input-group-text bg-primary text-white">
                                            <span>
                                                <i class="fa fa-search"></i>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="navbar-nav-scroll mt-4">
                                        <ul class="navbar-nav bd-navbar-nav flex-row">
                                            <li class="nav-item" v-for="(category, y) in $store.state.categories" :key="y">
                                                <a class="nav-link ml-2"><label data-v-5f1c8a7a="" class="badge badge-outline-dark">{{category.category}}</label></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-lg-9" >
                                <simple-card activeSearch activePerpage id="popularProduct" :items="products" :title="categoryName" col="col-lg-6 col-md-6 col-sm-6 col-12"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import simpleCard from '../components/pagination/simpleCard.vue';
const axios= require('axios').default
export default {
    name:'category',
    components:{
        simpleCard
    },
    data() {
        return{
            categoryName: '',
            products:[]
        }
    },
    watch: {
    },
    methods: {
        getProduct(ulid) {
            // let ulid= this.$route.params.ulid
            axios.get(`products/categories/${ulid}`)
            .then(resProduct=> {
                console.log({resProduct})
                this.products= resProduct.data.result
            })
            .catch(errProduct=> console.log({errProduct}))
        }
    },
    beforeRouteUpdate(to, from, next) {
        console.log('before',this.$route.path)
        this.getProduct(to.params.ulid)
        next()
    },
    mounted() {
        this.getProduct(this.$route.params.ulid)
        this.categoryName= this.$route.params.categoryName
    }
}
</script>